#modal li{
  line-height: 3.5;
}
.activeItem{
  font-weight: 600;
}

.inactiveItem{
  text-decoration: line-through;
  color:grey;
}


