@import "cssColors.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logoStyle{
  height: 150px;
}
.smallLogo {
  height: 40px;
  margin-right: 20px;
}
.imageLink{
  display: flex;
}
.centerContent{
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
}
#signInWrapper{
  margin-top:50px;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:nth-last-child(2) {
  margin-left: auto;
}
.elementOfInterest{
  display: flex;
  background-color: #F2C063;
  padding: 20px 10px;
  border: 1px solid #BF5B04;
  border-radius: 4px;
  margin-top: 10px;
  justify-content: space-between;
  flex-direction: column;
}
.elementOfInterest > div{
  margin: 10px 0;
}
.actionPanel{
  flex-direction: row;
}
.fullWidth{
  width:100%;
}
.noBorder{
  border: none;
}

@media only screen and (min-width: 600px) {
  .mobileMain{
    padding: 0 48px;
  }
}